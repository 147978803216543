<template>
  <default-layout>
    <v-container fluid tag="section">
      <page-loading :show="loading || loadingForm" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <v-row>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <billing-detail
              :detail="billingDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></billing-detail>
            <client-detail
              :detail="clientDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></client-detail>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <agent-detail
              :detail="agentDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></agent-detail>
            <owner-detail
              :detail="ownerDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></owner-detail>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <modal-billing></modal-billing>
    <modal-billing-payment></modal-billing-payment>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const BillingDetail = () => import('@/components/transaction-management/billing/billing-detail');
const AgentDetail = () => import('@/components/transaction-management/transaction/agent-detail');
const OwnerDetail = () => import('@/components/transaction-management/transaction/owner-detail');
const ClientDetail = () => import('@/components/transaction-management/transaction/client-detail');
const ModalBilling = () => import('@/components/modals/modal-billing');
const ModalBillingPayment = () => import('@/components/modals/modal-billing-payment');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    BillingDetail,
    AgentDetail,
    OwnerDetail,
    ClientDetail,
    ModalBilling,
    ModalBillingPayment,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.billing.loading,
      loadingForm: (state) => state.billing.form.loading,
      permissions: (state) => state.billing.permissions,
      allowedActions: (state) => state.billing.allowedActions,
      billingDetail: (state) => state.billing.detail,
      agentDetail: (state) => state.billing.agent,
      ownerDetail: (state) => state.billing.owner,
      clientDetail: (state) => state.billing.client,
    }),
  },
  async mounted() {
    await this.loadDetail();
  },
  methods: {
    async refreshAction() {
      await this.loadDetail();
    },
    async loadDetail() {
      try {
        this.$store.commit('billing/RESET_DEFAULT');
        const response = await this.$store.dispatch(
          'billing/getDetailInitData',
          this.$route.params.uuid,
        );
        console.log('DETAIL BILLING: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      }
    },
  },
};
</script>
